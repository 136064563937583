import * as React from "react";

import Layout from "../sections/Layout";
import Seo from "../sections/SEO";
import SimplePage from "../components/SimplePage"

import { navigate } from 'gatsby';
import yn from "yn";

const MAINTENANCE_MODE = yn(process.env.GATSBY_MAINTENANCE_MODE);

const IndexPage = () => {
  React.useEffect( redirectToOrder => {
    // redirect client side
    // can't do this in the component, because of SSR build
    if(MAINTENANCE_MODE) {
      navigate(`/maintenance/`);
    } else {
      navigate(`/order/`);
    }
  }, [])

  // from https://stackoverflow.com/a/65440496
  // This is a hack to circumvent a problem with emotion. I need to first mount 
  // nothing, otherwise wrong css gets applied when redirected via server-side
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setShow(true), 1)
  }, [])
  if (!show) return null

  return (
    <Layout title="Monitor">
      <SimplePage title={""} body={"Loading"} />
    </Layout>
  )
};

export default IndexPage;

export const Head = () => (
  <Seo />
)